'use client'

import Share from '@/app/_components/Icon/Share'
import { toast } from '@/app/_primitives/Toast'
import { cn } from '@/app/utils'

export default function ShareButton({
  id,
  width = 20,
  height = 20,
  className,
  fill,
}: {
  id?: string
  width?: number
  height?: number
  className?: string
  fill?: string
}) {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${window.location.origin}/collabo/${id}`)
    toast.success({
      title: '콜라보 URL을 복사했습니다.',
      content: '원하는 곳에 붙여넣기(Ctrl+V) 해주세요.',
    })
  }
  return (
    <div
      aria-hidden
      onClick={(e) => {
        e.preventDefault()
        copyToClipboard()
      }}
      className={cn(
        'span flex size-40 cursor-pointer items-center justify-center rounded-full border-1 border-tertiary',
        className,
      )}>
      <Share width={width} height={height} fill={fill ?? '#BCBFC2'} />
    </div>
  )
}
