'use client'

import {
  CollaboButtons,
  CollaboLikeCnt,
} from '@/app/_components/Collabo/CollaboCard'
import { Avatar } from '@/app/_components/Shared/Avatar'
import { CollaborationOpening } from '@/backend/models/CollaborationOpening'
import dayjs from 'dayjs'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

// FIXME
type OpenCollaboCardProps = {
  collaboration: CollaborationOpening
  isLoggedIn: boolean
}

type urlType = {
  type: string
  url: string
  displayUrl: string
}

export type collaboCompanyInfoType = {
  id: string
  name: string
  logoUrl: string
  thumbnailUrl: string
  order: string
  description: string
  urlArr: urlType[]
}

export const collaboCompanyInfo: collaboCompanyInfoType[] = [
  {
    id: 'ph4pKMevda4JENAFkIlqp',
    name: '벨오프',
    logoUrl: '/blogs/collabo/veiloff.png',
    thumbnailUrl: '/blogs/collabo/thumbnail/veiloff_thumbnail.png',
    order: 'order-2',
    description: `벨오프는 veil(장막) + off(제거되다)의 합성어로 '나의 베일을 걷어내다' 라는 의미입니다.\n 자신의 베일을 걷어내고 일상에서 다양한 스타일을 추구하는 사람들을 위한 주얼리 브랜드 입니다.`,
    urlArr: [
      { type: 'web', url: 'https://veiloff.com', displayUrl: 'veiloff.com' },
      {
        type: 'instagram',
        url: 'https://www.instagram.com/veiloff.official/',
        displayUrl: 'instagram.com/veiloff.official',
      },
    ],
  },
  {
    id: 'krmQW8qbxF6MJICl91zix',
    name: '(주)아무',
    logoUrl: '/blogs/collabo/amu.png',
    thumbnailUrl: '/blogs/collabo/thumbnail/amu_thumbnail.png',
    order: 'order-3',
    description: `Enjoy Vegan with us!\n 🍃함께할수록 즐거운 비건 라이프스타일 플랫폼, 비니티💚`,
    urlArr: [
      { type: 'web', url: 'https://vinity.co.kr/', displayUrl: 'vinity.co.kr' },
      {
        type: 'instagram',
        url: 'https://www.instagram.com/vinity_official',
        displayUrl: 'instagram.com/vinity_official',
      },
    ],
  },
  {
    id: 'P0mOTfwwcTdWRef203DOU',
    name: '훌템',
    logoUrl: '/blogs/collabo/bboyan.png',
    thumbnailUrl: '/blogs/collabo/thumbnail/bboyan_thumbnail.png',
    order: 'order-4',
    description: `WHOLETEM은 WHOLE와 ITEM을 조합하여 만든 회사명 입니다.\n 다양한 코어 테크놀러지 (열/살균/안전/소재)를 활용하여 다양한 제품 개발과 R&D 를 해나아가고 있습니다.`,
    urlArr: [
      {
        type: 'web',
        url: 'https://www.bboyan.co.kr/',
        displayUrl: 'bboyan.co.kr',
      },
      {
        type: 'instagram',
        url: 'https://www.instagram.com/bboyan_official/',
        displayUrl: 'instagram.com/bboyan_official',
      },
    ],
  },
  {
    id: 'IM_zgbqIF1OixNA7JDOok',
    name: '제이제이디자인',
    logoUrl:
      'https://d19bi7owzxc0m2.cloudfront.net/prod/users/sm8BKXznvpkURL-YOlc40.png',
    thumbnailUrl:
      'https://d19bi7owzxc0m2.cloudfront.net/prod/collaborations/KqX6Mmw5jZ9QAyU6pD55d.png',
    order: 'order-1',
    description: `제이제이디자인은 런칭한 메이든(ma[k]eden)은 식물을 통한 마음의 힐링을 선물하는 원예 소품 브랜드입니다. 책상 위의 작은 정원 플랜트 플레이트 테이블 가든! 회색빛 도시에서 하루 종일 지친 마음을 나의 테이블 위에 놓인 화단으로 위로받아보세요.`,
    urlArr: [
      {
        type: 'instagram',
        url: 'https://www.instagram.com/jjdesign3_official/',
        displayUrl: 'instagram.com/jjdesign3_official',
      },
      {
        type: 'web',
        url: 'https://xn--s39awr83qlwao10d5mc.kr/',
        displayUrl: '어떤공간이든.kr',
      },
      {
        type: 'instagram',
        url: 'https://www.instagram.com/eden_how/',
        displayUrl: 'instagram.com/eden_how',
      },
    ],
  },
]

// 태그 컴포넌트
function CollaboCategory(props: { category: string }) {
  return (
    <div className="c-b4 flex h-26 items-center justify-center rounded-full border-1 border-[#c0d0e8] px-8 text-[#c0d0e8]">
      {props.category}
    </div>
  )
}

function CollaboTagsContainer({ tags }: { tags: string[] }) {
  const containerRef = useRef<HTMLDivElement>(null)
  const moreTagsRef = useRef<HTMLDivElement>(null)
  const [visibleTags, setVisibleTags] = useState<string[]>([])
  const [hiddenTags, setHiddenTags] = useState<string[]>([])
  const [showPopover, setShowPopover] = useState(false)
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 })
  const [isMobile, setIsMobile] = useState(false)

  // 모바일 체크
  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768)
    }

    // 초기 체크
    checkIsMobile()

    // 화면 크기 변경 시 체크
    window.addEventListener('resize', checkIsMobile)
    return () => {
      window.removeEventListener('resize', checkIsMobile)
    }
  }, [])

  // 컨테이너 크기와 태그 계산을 위한 effect
  useEffect(() => {
    if (!containerRef.current || tags.length === 0) return

    // 태그 표시 계산 로직
    const calculateVisibleTags = () => {
      const container = containerRef.current
      if (!container) return

      // 컨테이너 너비 확인
      const containerWidth = container.clientWidth

      // 임시 DOM 구성을 위한 함수
      const measureTagsWithContainer = () => {
        // 임시 컨테이너 생성
        const tempContainer = document.createElement('div')
        tempContainer.className = container.className
        tempContainer.style.width = `${containerWidth}px`
        tempContainer.style.position = 'absolute'
        tempContainer.style.visibility = 'hidden'
        tempContainer.style.display = 'flex'
        tempContainer.style.flexWrap = 'wrap'
        tempContainer.style.gap = '4px'
        document.body.appendChild(tempContainer)

        // ∙∙∙ 태그 생성
        const moreTag = document.createElement('div')
        moreTag.className =
          'c-b4 flex h-26 items-center justify-center rounded-full border-1 border-[#c0d0e8] px-8 text-[#c0d0e8]'
        moreTag.textContent = '∙∙∙'

        // 모든 태그 요소 생성
        const tagElements: HTMLElement[] = []
        tags.forEach((tag) => {
          const tagEl = document.createElement('div')
          tagEl.className =
            'c-b4 flex h-26 items-center justify-center rounded-full border-1 border-[#c0d0e8] px-8 text-[#c0d0e8]'
          tagEl.textContent = tag
          tagElements.push(tagEl)
          tempContainer.appendChild(tagEl)
        })

        // 각 태그의 위치 확인
        let baselineY = tagElements[0].getBoundingClientRect().y
        let lastVisibleIndex = tags.length

        // 한 줄에 맞는 태그 찾기
        for (let i = 1; i < tagElements.length; i++) {
          const rect = tagElements[i].getBoundingClientRect()
          if (rect.y > baselineY + 5) {
            // 5px는 오차 허용치
            lastVisibleIndex = i
            break
          }
        }

        // 임시 컨테이너 초기화
        tempContainer.innerHTML = ''

        // 한 줄에 맞는 태그들과 ∙∙∙ 태그를 함께 배치
        if (lastVisibleIndex < tags.length) {
          // ∙∙∙ 태그를 위한 공간 확보
          const visibleTagElements = tagElements.slice(0, lastVisibleIndex)
          for (let i = 0; i < visibleTagElements.length; i++) {
            tempContainer.appendChild(visibleTagElements[i])
          }

          // ∙∙∙ 태그 추가 시도
          tempContainer.appendChild(moreTag)

          // ∙∙∙ 태그가 줄바꿈을 일으키는지 확인
          const moreTagRect = moreTag.getBoundingClientRect()
          if (moreTagRect.y > baselineY + 5) {
            // ∙∙∙ 태그가 두 번째 줄로 넘어가면 태그 하나 더 제거
            if (lastVisibleIndex > 0) {
              lastVisibleIndex--
            }
          }
        }

        document.body.removeChild(tempContainer)
        return lastVisibleIndex
      }

      // 태그 측정 및 계산
      const lastVisibleIndex = measureTagsWithContainer()

      // 태그 상태 업데이트
      if (lastVisibleIndex < tags.length) {
        setVisibleTags(tags.slice(0, lastVisibleIndex))
        setHiddenTags(tags.slice(lastVisibleIndex))
      } else {
        // 모든 태그가 한 줄에 맞음
        setVisibleTags(tags)
        setHiddenTags([])
      }
    }

    // 초기 계산
    // 컴포넌트 마운트 시 약간 지연 후 계산
    const timer = setTimeout(() => {
      calculateVisibleTags()
    }, 50)

    // 창 크기 변경 시 다시 계산
    const handleResize = () => calculateVisibleTags()
    window.addEventListener('resize', handleResize)

    return () => {
      clearTimeout(timer)
      window.removeEventListener('resize', handleResize)
    }
  }, [tags])

  // 팝오버 위치 계산 및 표시
  const updatePopoverPosition = () => {
    if (hiddenTags.length === 0 || !moreTagsRef.current) return

    const rect = moreTagsRef.current.getBoundingClientRect()

    if (isMobile) {
      // 모바일에서는 오른쪽 정렬
      setPopoverPosition({
        top: rect.bottom + window.scrollY + 5,
        left: Math.max(5, rect.right - 200 + window.scrollX), // 팝오버 너비를 고려해 오른쪽 정렬
      })
    } else {
      // 데스크톱에서는 기존 방식
      setPopoverPosition({
        top: rect.bottom + window.scrollY + 5,
        left: rect.left + window.scrollX,
      })
    }
  }

  // 팝오버 표시/숨김 핸들러
  const handleMoreTagsMouseEnter = () => {
    if (isMobile) return // 모바일에서는 호버 이벤트를 무시

    updatePopoverPosition()
    setShowPopover(true)
  }

  // 모바일에서 클릭 핸들러
  const handleMoreTagsClick = (e: React.MouseEvent) => {
    // 이벤트 확산 및 기본 동작 중지
    e.stopPropagation()
    e.preventDefault()

    updatePopoverPosition()
    setShowPopover(!showPopover)
  }

  // 외부 클릭 감지용 effect
  useEffect(() => {
    if (!showPopover) return

    const handleClickOutside = (e: MouseEvent) => {
      if (
        moreTagsRef.current &&
        !moreTagsRef.current.contains(e.target as Node) &&
        !(e.target as Element).closest('.tags-popover')
      ) {
        setShowPopover(false)
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [showPopover])

  return (
    <div
      ref={containerRef}
      className="flex h-26 flex-wrap gap-4 overflow-hidden">
      {visibleTags.map((tag, idx) => (
        <CollaboCategory key={idx} category={tag} />
      ))}

      {hiddenTags.length > 0 && (
        <div
          ref={moreTagsRef}
          className="more-tags cursor-pointer"
          onMouseEnter={handleMoreTagsMouseEnter}
          onMouseLeave={() => (isMobile ? null : setShowPopover(false))}
          onClick={handleMoreTagsClick}>
          <CollaboCategory category="∙∙∙" />
        </div>
      )}

      {showPopover && hiddenTags.length > 0 && (
        <TagsPopover tags={hiddenTags} position={popoverPosition} />
      )}
    </div>
  )
}

// TagsPopover 컴포넌트도 수정
function TagsPopover({
  tags,
  position,
}: {
  tags: string[]
  position: { top: number; left: number }
}) {
  return createPortal(
    <div
      className="tags-popover absolute z-50 rounded-10 border border-[#e0e0e0] bg-white p-12 shadow-lg"
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
        minWidth: '100px',
        maxWidth: '90vw',
      }}>
      <div className="flex flex-wrap gap-4">
        {tags.map((tag, idx) => (
          <CollaboCategory key={idx} category={tag} />
        ))}
      </div>
    </div>,
    document.body,
  )
}

// 상태 배지 컴포넌트
export function CollaboStatusBadge(props: { status: string }) {
  return (
    <>
      {props.status === 'ended' || props.status === 'finished' ? (
        <div className="relative flex items-center gap-5">
          <div className="relative flex h-20 w-44 items-center justify-center rounded-5 bg-txt-secondary text-10 font-[700] leading-[160%] text-white">
            마감
          </div>
        </div>
      ) : props.status === 'in-progress' ? (
        <div className="relative flex items-center gap-5">
          <div className="relative flex h-20 w-44 items-center justify-center rounded-5 bg-french-bleu text-10 font-[700] leading-[160%] text-white">
            진행중
          </div>
        </div>
      ) : props.status === 'result' || props.status === 'result-announced' ? (
        <div className="relative flex items-center gap-5">
          <div className="relative flex h-20 w-55 items-center justify-center rounded-5 bg-[#23ac8a] text-10 font-[700] leading-[160%] text-white">
            🏅결과발표
          </div>
        </div>
      ) : null}
    </>
  )
}

// 메인 OpenCollaboCard 컴포넌트
export default function OpenCollaboCard({
  collaboration,
  isLoggedIn,
}: OpenCollaboCardProps) {
  const daysLeft = useMemo(() => {
    if (collaboration.status !== 'in-progress') return 0

    const now = dayjs()
    const date = dayjs.utc(collaboration.endDate).local()

    return date.diff(now, 'day')
  }, [collaboration.endDate, collaboration.status])
  // let estimate = collaboration.estimatedBudget
  // const refineBudget = estimate.match(/\d+/g)
  // if (refineBudget && refineBudget.length > 0) {
  //   const estimatedBudget = parseInt(refineBudget.join(''), 10)
  //   estimate =
  //     estimatedBudget > 10000
  //       ? `${estimatedBudget / 10000}만원`
  //       : `${estimatedBudget}원`
  // }
  const randomHref = () => {
    if (collaboration.id === 'A/B테스트 하는 ID 추가') {
      const magicNum = Math.floor(Math.random() * (10 - 1) + 1)

      if (magicNum % 2 === 0) {
        return `/collabo/${collaboration.id}`
      } else {
        return `/collabo-v2/${collaboration.id}`
      }
    }

    // 킹율, 요코하마, LG 스탠 바이미, SPACE A 아쿠아 플라넷
    if (
      collaboration.id === 'UyhC-wVqddhlgpsXAa_f2' ||
      collaboration.id === 'QxvELIfJm5LooWfJB97i3' ||
      collaboration.id === 'aObrnHZWvmUtlw-sgQD9w' ||
      collaboration.id === '10PoO9CRRvAcsABjCd27y' ||
      collaboration.id === 'RkMy1hUiU1tKzCFTh5gvJ'
    ) {
      return `/collabo-v2/${collaboration.id}`
    }
    return `/collabo/${collaboration.id}`
  }

  return (
    <Link
      href={randomHref()}
      className={
        'group relative flex w-300 flex-col rounded-20 transition-transform duration-300 ease-in-out hover:-translate-y-10 hover:shadow-xl max-lg:w-full max-lg:border-1 max-lg:border-border-base lg:shadow-card-web'
      }>
      <div className="relative h-200 w-full overflow-hidden rounded-t-20 max-lg:hidden">
        <Image
          src={collaboration.imageURL}
          fill
          alt={collaboration.companyName}
          className="object-cover"
        />
        <CollaboButtons
          collaboId={collaboration.id}
          isLiked={collaboration.isLiked}
          isLoggedIn={isLoggedIn}
          className="absolute bottom-12 right-12"
        />
      </div>
      <div className="relative flex flex-col gap-10 p-20">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4 text-[#404040]">
            <CollaboStatusBadge status={collaboration.status} />
            {collaboration.status === 'in-progress' ? (
              <div className="c-h8">{daysLeft}일 남음</div>
            ) : collaboration.status === 'ended' ? (
              <div className="c-h8">
                {collaboration.id === 'bGMkyG_1XUw8ap6cgMRkV'
                  ? '결과 발표 대기'
                  : '결과 발표'}
              </div>
            ) : (
              ''
            )}
          </div>

          <CollaboButtons
            collaboId={collaboration.id}
            isLiked={collaboration.isLiked}
            isLoggedIn={isLoggedIn}
            className="lg:hidden"
          />
        </div>
        <div className="c-b4 line-clamp-2 w-full lg:c-b3">
          {collaboration.companyName}
        </div>
        <CollaboTagsContainer
          tags={collaboration.tags.filter((v) => v.trim() !== '')}
        />
        <div className="flex w-full items-center justify-between">
          <Avatar
            userInfo={collaboration.company}
            href={`/u/${collaboration.company.id}`}
          />
          <CollaboLikeCnt
            isLiked={Boolean(collaboration.isLiked)}
            cnt={collaboration.likedCount ?? 0}
            id={collaboration.id}
            isLoggedIn={isLoggedIn}
          />
        </div>
      </div>
    </Link>
  )
}
