'use client'

import { CollaboStatusBadge } from '@/app/_components/Collabo/OpenCollaboCard'
import ShareButton from '@/app/_components/CollaboDetail/DetailHero/_components/ShareButton'
import Heart from '@/app/_components/Icon/Heart'
import { Avatar } from '@/app/_components/Shared/Avatar'
import { LikeButton } from '@/app/_components/Shared/LikeButton'
import { cn } from '@/app/utils'
import { likeAndFollow } from '@/backend/apis/like-and-follow/LikeAndFollowAPI'
import { CollaborationOpening } from '@/backend/models/CollaborationOpening'
import { useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { useMemo } from 'react'

type CollaboCardType = {
  collaboration: CollaborationOpening
}

export default function CollaboCard({ collaboration }: CollaboCardType) {
  const daysLeft = useMemo(() => {
    if (collaboration.status !== 'in-progress') return 0

    const now = dayjs()
    const date = dayjs.utc(collaboration.endDate).local()

    return date.diff(now, 'day')
  }, [collaboration.endDate, collaboration.status])

  const randomHref = () => {
    if (collaboration.id === 'A/B테스트 할 id') {
      const magicNum = Math.floor(Math.random() * (10 - 1) + 1)
      if (magicNum % 2 === 0) {
        return `/collabo/${collaboration.id}`
      } else {
        return `/collabo-v2/${collaboration.id}`
      }
    }
    // 킹율, 요코하마, LG스탠바이미, SPACE V, 아쿠아 플라넷
    if (
      collaboration.id === 'UyhC-wVqddhlgpsXAa_f2' ||
      collaboration.id === 'QxvELIfJm5LooWfJB97i3' ||
      collaboration.id === 'aObrnHZWvmUtlw-sgQD9w' ||
      collaboration.id === '10PoO9CRRvAcsABjCd27y' ||
      collaboration.id === 'RkMy1hUiU1tKzCFTh5gvJ'
    ) {
      return `/collabo-v2/${collaboration.id}`
    }

    return `/collabo/${collaboration.id}`
  }

  return (
    <Link
      href={randomHref()}
      className="group relative w-300 cursor-pointer overflow-hidden rounded-20 shadow-card-mobile transition-transform duration-300 ease-in-out hover:-translate-y-10 hover:shadow-xl xl:shadow-card-web"
      aria-hidden="true">
      <div className="relative size-300 overflow-hidden">
        <Image
          src={collaboration.imageURL}
          alt={collaboration.productName}
          className="object-cover"
          fill
        />
        <CollaboButtons
          collaboId={collaboration.id}
          isLiked={collaboration.isLiked}
          className="absolute bottom-12 right-12"
        />
      </div>
      <div className="relative flex flex-col gap-20 p-24">
        <div className="flex flex-col gap-12">
          <div className="flex gap-4">
            <CollaboStatusBadge status={collaboration.status} />
            {collaboration.status === 'in-progress' ? (
              <div className="c-b4 font-[700]">{daysLeft}일 남음</div>
            ) : collaboration.status === 'ended' ? (
              <div className="c-h8">
                {collaboration.id === 'bGMkyG_1XUw8ap6cgMRkV'
                  ? '결과 발표 대기'
                  : '결과 발표'}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="c-b4 line-clamp-2 text-[#162220]">
            {collaboration.companyName}
          </div>
        </div>
        <div
          role="none"
          className="flex w-full cursor-default items-center justify-between">
          <Avatar
            userInfo={collaboration.company}
            href={`/u/${collaboration.company.id}`}
          />
          {/* <CollaboLikeCnt cnt={2059} /> */}
        </div>
        <div className="flex flex-wrap gap-4">
          {collaboration.tags.map((v, i) => {
            if (v.trim() === '') return
            return <CollaboCategory key={i} category={v} />
          })}
        </div>
      </div>
    </Link>
  )
}

export function CollaboButtons(props: {
  className?: string
  collaboId: string
  isLiked?: boolean
  isLoggedIn?: boolean
}) {
  return (
    <div
      className={cn(
        'z-10 flex gap-4 opacity-0 transition-all max-lg:opacity-100 lg:group-hover:opacity-100',
        props.className,
      )}>
      {props.isLoggedIn && (
        <LikeButton id={props.collaboId} type="collabo" className="">
          <span className="span flex size-32 cursor-pointer items-center justify-center rounded-full border-1 border-tertiary bg-white">
            <Heart
              width={16}
              height={16}
              fill={props.isLiked ? '#CB311F' : '#bcbfc2'}
            />
          </span>
        </LikeButton>
      )}
      {!props.isLoggedIn && (
        <Link href={`/login?callbackUrl=/collabo`}>
          <span className="span flex size-32 cursor-pointer items-center justify-center rounded-full border-1 border-tertiary bg-white">
            <Heart className={'size-16'} />
          </span>
        </Link>
      )}
      <ShareButton
        id={props.collaboId}
        width={16}
        height={16}
        className="size-32 bg-white"
      />
    </div>
  )
}

export function CollaboLikeCnt(props: {
  cnt: number
  isLiked: boolean
  id: string
  isLoggedIn: boolean
}) {
  const router = useRouter()
  const mutation = useMutation({
    mutationFn: async () => {
      await likeAndFollow({
        id: props.id,
        type: 'collabo',
      })
    },
  })
  return (
    <div
      aria-hidden
      className="flex items-center gap-8"
      onClick={async (e) => {
        e.preventDefault()

        if (props.isLoggedIn) {
          mutation.mutate()
        } else {
          router.push('/login?callbackUrl=/collabo')
        }
      }}>
      <Heart
        width={14}
        height={14}
        fill={props.isLiked ? '#CB311F' : '#d4d4d4'}
      />
      <span
        className={cn('c-b4 text-[#d4d4d4]', {
          '!text-[#CB311F]': props.isLiked,
        })}>
        {props.cnt.toLocaleString()}
      </span>
    </div>
  )
}

function CollaboCategory(props: { category: string }) {
  return (
    <div className="c-b4 flex h-26 items-center justify-center rounded-full border-1 border-[#c0d0e8] px-8 text-[#c0d0e8]">
      {props.category}
    </div>
  )
}
